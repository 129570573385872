<template>
  <main class="main" data-view="exchange">
    <section class="section" id="s1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <ui-title tag="2" class="mb-3">
              {{ cc.s2.title }}
            </ui-title>
            <ui-text class="tc-gray lh-175 mb-md-4">
              {{ cc.s2.text }}
            </ui-text>
            <div class="img img--exchange img--exchange-s2-1">
              <img src="/img/pages/exchange/img-s2-1.png" alt="exhcnage" />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="exchange mb-5 pb-lg-4">
              <div class="ico ico--exchange mb-md-4 me-3 me-md-4"
                   :class="`ico--exchange-${cc.s2.list[0].theme}`">
                <img :src="`/img/icons/ico-${cc.s2.list[0].key}-${cc.s2.list[0].theme}.svg`" :alt="cc.s2.list[0].key"/>
              </div>
              <div>
                <ui-text tag="2" class="mb-3">
                  {{ cc.s2.list[0].title }}
                </ui-text>
                <ui-text tag="4" class="tc-gray lh-175 ta-center ta-md-start mb-4">
                  {{ cc.s2.list[0].text }}
                </ui-text>
                <ui-link :data="cc.s2.list[0].link" :modifier="cc.s2.list[0].link.modifier" :color="cc.s2.list[0].link.theme" class="ts-16 ts-md-20" />
              </div>
            </div>
            <div class="exchange">
              <div class="ico ico--exchange mb-md-4 me-3 me-md-4"
                   :class="`ico--exchange-${cc.s2.list[1].theme}`">
                <img :src="`/img/icons/ico-${cc.s2.list[1].key}-${cc.s2.list[1].theme}.svg`" :alt="cc.s2.list[1].key"/>
              </div>
              <div>
                <ui-text tag="2" class="mb-3">
                  {{ cc.s2.list[1].title }}
                </ui-text>
                <ui-text tag="4" class="tc-gray lh-175 ta-center ta-md-start d-inline">
                  {{ cc.s2.list[1].text[0] }}
                </ui-text>
                <ui-text tag="4" class="lh-175 ta-center ta-md-start d-inline" style="text-decoration: underline">
                  {{ cc.s2.list[1].text[1] }}
                </ui-text>
                <ui-text tag="4" class="tc-gray lh-175 ta-center ta-md-start d-inline">
                  {{ cc.s2.list[1].text[2] }}
                </ui-text>
                <div class="mb-4">
                </div>
                <ui-link :data="cc.s2.list[1].link" :modifier="cc.s2.list[1].link.modifier" :color="cc.s2.list[1].link.theme" class="ts-16 ts-md-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiLink from '@/components/UI/Link'
export default {
  components: {
    uiTitle, uiText, uiLink
  },
  computed: {
    cc () {
      return this.$t('pages.exchange')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.documents')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
